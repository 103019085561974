import { Route } from "react-router-dom";
import {
  CourierPage,
  CourierHomepage,
  DeliveryAdvertDetails,
  VerificationDeliveryDetails,
  VerificationCode,
  CourierDashboard,
  CourierBookings,
  WalletSettings,
  MyRequestDetails,
  CourierHistory,
  CourierUnstake,
  SuccessfulStake,
  CourierStake,
  CourierWithdraw,
  CourierSetup,
  // StarterStake,
  // UpgradeStake,
} from "@/pages";
export const CourierRoutes = () => {
  return (
    <Route element={<CourierPage />} path="courier">
      <Route element={<CourierHomepage />} path="" />
      <Route element={<DeliveryAdvertDetails />} path="advert/:deliveryId" />
      <Route element={<CourierSetup />} path="courier-setup" />
      <Route
        element={<VerificationDeliveryDetails />}
        path="verification-code/:deliveryId"
      />
      <Route element={<VerificationCode />} path="verification-code" />
      <Route element={<CourierDashboard />} path="dashboard" />
      <Route element={<CourierBookings />} path="bookings" />
      <Route element={<WalletSettings />} path="wallet-settings" />
      <Route element={<MyRequestDetails />} path=":deliveryId" />
      <Route element={<CourierHistory />} path="courier-history" />
      <Route element={<CourierUnstake />} path="courier-unstake" />
      <Route element={<CourierStake />} path="courier-stake" />
      <Route element={<CourierWithdraw />} path="courier-withdraw" />
      <Route element={<SuccessfulStake />} path="unstake-success" />
      {/* <Route element={<StarterStake />} path="starter-stake" />
    <Route element={<UpgradeStake />} path="upgrade-stake" /> */}
    </Route>
  );
};
