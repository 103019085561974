import { Route } from "react-router-dom";
import {
  ProfilePage,
  PailotReferral,
  NotificationsPage,
  TermAndCondPage,
  PrivacyPolicy,
} from "@/pages";
export const ProfileRoutes = () => {
  return (
    <Route path="profile">
      <Route element={<ProfilePage />} index />
      <Route element={<PailotReferral />} path="pailot-referral" />
      <Route element={<NotificationsPage />} path="settings/notifications" />
      <Route
        element={<TermAndCondPage />}
        path="settings/terms-and-conditions"
      />
      <Route element={<PrivacyPolicy />} path="settings/privacy-policy" />
    </Route>
  );
};
