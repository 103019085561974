import { Toaster } from "react-hot-toast";

export const ToasterConfig = () => {
  return (
    <Toaster
      containerStyle={{ margin: "8px" }}
      gutter={12}
      position="top-center"
      toastOptions={{
        success: {
          duration: 4000,
          iconTheme: {
            primary: "var(--success-60)",
            secondary: "var(--primary-light)",
          },
          style: {
            borderLeft: "7px var(--success-60) solid",
          },
        },
        error: {
          duration: 5000,

          style: {
            borderLeft: "7px var(--critical-60) solid",
          },
        },
        icon: "",
        style: {
          fontSize: "16px",
          zIndex: "999",
          minWidth: "100%",
          justifyContent: "flex-start",

          paddingLeft: "0px",
          paddingRight: "0px",
          marginRight: "0px",
          marginLeft: "0px",
          backgroundColor: "white",
          color: "var(--color-grey-700)",
        },
      }}
    />
  );
};
