import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend) // Load translations using http backend
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Bind to React
  .init({
    fallbackLng: "en", // Default language
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator"],
      lookupQuerystring: "lng", // Detect language from URL query parameter (e.g., ?lng=fr)
      lookupCookie: "i18next", // Detect language from a cookie
      lookupLocalStorage: "i18nextLng", // Key to use in localStorage
      caches: ["localStorage", "cookie"], // Save detected language in both localStorage and cookies
    },
    debug: true, // Enable console logs for debugging
    ns: ['common', 'chat', 'courier', 'onboarding', 'sender', 'profile', 'receiver'], // List of namespaces
    defaultNS: 'common', // Default namespace for keys
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // Path to translation files
    },
  });

export default i18n;
