// import "./index.css";
import "./styles/globals.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Providers } from "@/Store/provider";

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import i18n configuration
import { SocketProvider } from "./contexts/socket-provider";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <SocketProvider>
        <Providers>
          <App />
        </Providers>
      </SocketProvider>
    </I18nextProvider>
  </React.StrictMode>,
);
