import { Button } from "@/ui/button";
import { Check } from "lucide-react";
import { motion } from "framer-motion";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SuccessPage = () => {
  const { t } = useTranslation("common");
  const { state } = useLocation();

  const navigate = useNavigate();

  const mode = state.mode;
  const deliveryID = state.deliveryID;
  const chatId = state.chatId;

  const goToHome = () => {
    if (mode === "staking") {
      navigate("/courier", { replace: true });
    } else if (chatId) {
      navigate(`/chat/${chatId}`, { state: { chatId } });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {mode === "staking" ? (
        <div className="flex h-[100dvh] w-[100vw] flex-col gap-4 p-4">
          <div className="flex justify-end">
            <Button fill="ghost" onClick={goToHome}>
              {t("SuccessPage.done")}
            </Button>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-4 ">
            <motion.div
              animate={{ scale: 1, opacity: 1 }}
              className="my-4 flex items-center justify-center"
              initial={{ scale: 0, opacity: 0 }}
            >
              <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-green-50 ">
                <Check color="#039855" size={45} />
              </div>
            </motion.div>
            <h4 className="text-center text-xl">{t("SuccessPage.Staking.title")}</h4>
            <p className="w-[70%] self-center text-center text-sm text-gray-600">
              {t("SuccessPage.Staking.description1")} <br /> {t("SuccessPage.Staking.description2")}
            </p>
          </div>
          <div className="flex w-full  items-center justify-center gap-4">
            <Link className="w-full" to="/courier/dashboard">
              <Button className="w-full">{t("SuccessPage.Staking.button")}</Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex h-[100dvh] w-[100vw] flex-col gap-4 p-4">
          <div className="flex justify-end">
            <Button fill="ghost" onClick={goToHome}>
              {t("SuccessPage.done")}
            </Button>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-4 ">
            <motion.div
              animate={{ scale: 1, opacity: 1 }}
              className="my-4 flex items-center justify-center"
              initial={{ scale: 0, opacity: 0 }}
            >
              <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-green-50 ">
                <Check color="#039855" size={45} />
              </div>
            </motion.div>
            <h4 className="text-center text-xl">{t("SuccessPage.Payment.title")}</h4>
            <p className="w-[70%] self-center text-center text-sm text-gray-600">
              {t("SuccessPage.Payment.description")}
            </p>
          </div>
          <div className="flex w-full flex-col  items-center justify-center gap-4">
            <Link className="w-full" to="/sender">
              <Button
                className="w-full hover:bg-gray-200 hover:text-pi-primary-default"
                fill="outline"
              >
                {t("SuccessPage.Payment.button1")}
              </Button>
            </Link>
            <Link
              className="w-full"
              state={{
                deliveryId: deliveryID,
              }}
              to="/receiver/timeline"
            >
              <Button className="w-full">{t("SuccessPage.Payment.button2")}</Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessPage;
