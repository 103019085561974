import { Route } from "react-router-dom";
import { WelcomePage } from "@/pages";
import SuccessPage from "@/components/success-page/success-page";
export const OtherRoutes = () => {
  return (
    <>
      <Route element={<WelcomePage />} path="welcome" />
      <Route element={<SuccessPage />} path="/success" />
    </>
  );
};
