import { Route } from "react-router-dom";
import {
  SenderPage,
  SenderHomepage,
  SenderDeliveries,
  CourierInfo,
  DeliveryForm,
} from "@/pages";
export const SenderRoutes = () => {
  return (
    <Route element={<SenderPage />} path="sender">
      <Route element={<SenderHomepage />} path="" />
      <Route element={<SenderDeliveries />} path="delivery" />
      <Route element={<CourierInfo />} path="courier-info" />
      <Route element={<DeliveryForm />} path="new-delivery" />
    </Route>
  );
};
