import { Route } from "react-router-dom";
import {
  ReceiverPage,
  ReceiverTimeline,
  ReceiverDelivery,
  ReceiverPackage,
} from "@/pages";
export const ReceiverRoutes = () => {
  return (
    <Route element={<ReceiverPage />} path="receiver">
      <Route element={<ReceiverTimeline />} path="timeline" />
      <Route element={<ReceiverDelivery />} path="delivery" />
      <Route element={<ReceiverPackage />} path="package" />
    </Route>
  );
};
