import { Suspense } from "react";
 import { RootState } from "@/Store";
 import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
 import { Loader } from "@/components";
 import { useSelector } from "react-redux";
 import { OnboardingRoutes } from "./routes/onboarding-routes";
 import { SenderRoutes } from "./routes/sender-routes";
 import { ReceiverRoutes } from "./routes/receiver-routes";
 import { useGeolocation } from "./hooks/use-geolocation";
 import { ToasterConfig } from "./components/toaster-config";
 import { OtherRoutes } from "./routes/other-routes";
 import { ProfileRoutes } from "./routes/profile-routes";
 import { ChatRoutes } from "./routes/chat-routes";
 import { CourierRoutes } from "./routes/courier-routes"; 

 function App() {
   const user = useSelector((state: RootState) => state.auth.user);
   const accessToken = localStorage.getItem("accessToken");
   useGeolocation(user, accessToken);

   return (
     <BrowserRouter>
       <Suspense fallback={<Loader />}>
         <Routes>
           {/* Import route groups */}
           {OnboardingRoutes()}
           {OtherRoutes()}
           {ProfileRoutes()}
           {ChatRoutes()}
           {CourierRoutes()}
           {SenderRoutes()}
           {ReceiverRoutes()}
           <Route
             path="*"
             element={
               <Navigate to={`/${user?.activeProfile ? user.activeProfile.name : ""}`} />
             }
           />
         </Routes>
      </Suspense>
      <ToasterConfig />
    </BrowserRouter>
  );
}
export default App;