/* eslint-disable @typescript-eslint/no-unused-vars */
import { RootState } from "@/Store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from "@/Store/Features/authSlice";

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  // Handle 401 (Unauthorized) response
  if (
    result.error &&
    (result.error.status === 401 || result.error.status === 403)
  ) {
    localStorage.removeItem("accessToken");
    api.dispatch(logout());
    const query = window.location.search;
    window.location.replace(`/${query}`); // Fix until a Route guard is implemented
  }

  return result;
};

const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (_builder) => ({}),
});

export default baseApi;
