import { lazy } from "react";

// AUTH PAGES
export const OnBoarding = lazy(() => import("./onboarding/onboarding"));
export const WelcomePage = lazy(() => import("./welcom-page/welcome-page"));

// PROFILE PAGES
export const ProfilePage = lazy(() => import("./profile-page/profile-page"));
export const PrivacyPolicy = lazy(() => import("./profile-page/privacy-policy"));
export const NotificationsPage = lazy(() => import("./profile-page/notifications-page"));
export const TermAndCondPage = lazy(() => import("./profile-page/terms-and-conditions"));
export const PailotReferral = lazy(() => import("./pailot-referral/PailotReferral"));

// CHAT PAGES
export const AllChat = lazy(() => import("./chat-page/all-chats"));
export const ChatScreen = lazy(() => import("./chat-page/chat-screen"));

// SENDER PAGES
export const SenderPage = lazy(() => import("./sender-page/sender-page"));
export const SenderHomepage = lazy(
  () => import("./sender-page/sender-homepage"),
);
export const SenderDeliveries = lazy(
  () => import("./sender-page/sender-deliveries"),
);
export const CourierInfo = lazy(() => import("./sender-page/courier-info"));
export const DeliveryForm = lazy(
  () => import("../components/delivery-form/delivery-form"),
);

// RECEIVER PAGES
export const ReceiverPage = lazy(() => import("./receiver-page/receiver-page"));
export const ReceiverTimeline = lazy(
  () => import("./receiver-page/receiver-timeline"),
);
export const ReceiverDelivery = lazy(
  () => import("./receiver-page/receiver-delivery"),
);
export const ReceiverPackage = lazy(
  () => import("./receiver-page/receiver-package"),
);

// COURIER PAGES

export const CourierPage = lazy(() => import("./courier-pages/courier-page"));
export const CourierHomepage = lazy(
  () => import("./courier-pages/courier-homepage"),
);
export const MyRequestDetails = lazy(
  () => import("./courier-pages/my-request-details"),
);
export const CourierDashboard = lazy(
  () => import("./courier-pages/courier-dashboard"),
);
export const WalletSettings = lazy(
  () => import("./courier-pages/wallet-settings"),
);
export const CourierHistory = lazy(
  () => import("./courier-pages/Courier-history"),
);
export const CourierUnstake = lazy(
  () => import("./courier-pages/courier-unstake"),
);
export const UnstakeSuccess = lazy(
  () => import("./courier-pages/unstake-success"),
);
export const CourierStake = lazy(() => import("./courier-pages/courier-stake"));
export const StarterStake = lazy(() => import("./courier-pages/starter-stake"));
export const UpgradeStake = lazy(() => import("./courier-pages/upgrade-stake"));
export const CourierSetup = lazy(() => import("./courier-pages/courier-setup"));
export const VerificationCode = lazy( () => import("./courier-pages/v-code"));
export const DeliveryAdvertDetails = lazy( () => import("./courier-pages/delivery-advert-details"));
export const VerificationDeliveryDetails = lazy( () => import("./courier-pages/verification-delivery-details"));
export const CourierBookings = lazy( () => import("./courier-pages/courier-bookings"));
export const SuccessfulStake = lazy( () => import("./courier-pages/unstake-success"));
export const CourierWithdraw = lazy( () => import("./courier-pages/courier-withdraw"));