import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateUserAddress } from "@/Store/Features/authSlice";
import { useUpdateUserLocationMutation } from "@/services/user.services";

const GEOAPIFY_BASE_URL = "https://api.geoapify.com/v1";
const API_KEY = import.meta.env.VITE_PUBLIC_GEOAPIFY_API_KEY;

export const useGeolocation = (
  user: any,
  accessToken: string | null,
) => {
  const [updateUserLocation] = useUpdateUserLocationMutation();
  const dispatch = useDispatch();

  // Refs to store the latest coordinates
  const coordsRef = useRef<{ latitude: number; longitude: number } | null>(
    null,
  );

  useEffect(() => {
    if (!navigator.geolocation || !user || !accessToken) return;

    let locationInterval: NodeJS.Timeout | null = null;
    let addressInterval: NodeJS.Timeout | null = null;

    const updateLocation = () => {
      if (coordsRef.current) {
        const { latitude, longitude } = coordsRef.current;
        updateUserLocation({ lat: latitude, lng: longitude });
      }
    };

    const fetchUserAddress = async () => {
      if (coordsRef.current) {
        const { latitude, longitude } = coordsRef.current;
        try {
          const response = await fetch(
            `${GEOAPIFY_BASE_URL}/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=${API_KEY}`,
          );

          if (!response.ok) throw new Error("Could not fetch user address");

          const data = await response.json();
          const { city, country } = data.features[0].properties;
          dispatch(updateUserAddress({ city, country }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      }
    };

    const handlePosition = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      // Update the latest coordinates in the ref
      coordsRef.current = { latitude, longitude };
    };

    const errorHandler = (error: GeolocationPositionError) => {
      console.error("Geolocation error:", error.message);
    };

    fetchUserAddress();

    // Start watching the position
    const watchId = navigator.geolocation.watchPosition(
      handlePosition,
      errorHandler,
      {
        enableHighAccuracy: true,
      },
    );

    // Start intervals
    locationInterval = setInterval(updateLocation, 5000);
    addressInterval = setInterval(fetchUserAddress, 300000);

    // Cleanup function
    return () => {
      if (locationInterval) clearInterval(locationInterval);
      if (addressInterval) clearInterval(addressInterval);
      navigator.geolocation.clearWatch(watchId);
    };
  }, [accessToken, user, dispatch, updateUserLocation]);
};
