import { Route } from "react-router-dom";
import { AllChat, ChatScreen } from "@/pages";

export const ChatRoutes = () => {
  return (
    <Route path="chat">
      <Route element={<AllChat />} index />
      <Route element={<ChatScreen />} path=":chat-id" />
    </Route>
  );
};
